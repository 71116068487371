.Mobilehometop-container {
  padding-top: 80px;
  height: calc(100vh - 80px);
  /* background-color: red; */
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Mobilehometop-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* position: relative; */

  height: 50vh;
  width: 90vw;
}
.Mobilehometop-video {
  width: 100vw;
  object-fit: contain;
}

.Mobilehometop-icon1 {
  position: absolute;
  left: 20vw;
  top: 30vh;
}
.Mobilehometop-icon2 {
  position: absolute;
  right: 15vw;
  bottom: 50vh;
}
.Mobilehometop-icon3 {
  position: absolute;
  left: 15vw;
  top: 10vh;
}
.Mobilehometop-icon4 {
  position: absolute;
  bottom: 80px;
  left: 0;
}

.Mobilehometop-title > h1 {
  text-align: center;
  width: 80vw;
  height: 30vh;
  /* background-color: red; */
  position: absolute;
  top: 10vh;
  /* left: 10vw; */
}
.Mobilehometop-title > h1 > p {
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;

  align-items: center;
  text-align: center;

  color: #808080;
}

.Mobilehometop-span {
  font-family: "Plus Jakarta Sans";
  /* display: block; */
  font-weight: 700;
  font-size: 36px;
  line-height: 100%;

  color: #111727;
  /* white-space: nowrap; */
}

.Mobilehometop-span2 {
  /* white-space: nowrap; */
  font-family: "Plus Jakarta Sans";

  font-weight: 700;
  font-size: 36px;
  line-height: 100%;

  color: #111727;

  background: linear-gradient(
    to bottom,
    transparent 65%,
    #b2f417 65%,
    #b2f417 85%,
    transparent 50%
  );
}
.Mobilehometop-span3 {
  font-weight: 500;
  font-size: 14px;
  line-height: 135%;
  /* or 24px */
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 3vh;

  /* Base/80 */
  color: #808080;
}
.special-color-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Mobilehometop-btn {
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  gap: 4px;

  width: 249px;
  height: 56px;

  /* Base/100 */
  border: 1.5px solid #1f1f1f;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  background-color: #ffffff;

  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  /* position: absolute;
  bottom: calc(20vh + 80px); */
  margin-top: 5vh;
  cursor: pointer;
}
.Mobilehometop-btn-img {
  margin-left: 5px;
}
