.hometop-container {
  padding-top: 80px;
  height: calc(100vh - 80px);
  /* background-color: red; */
  width: 100vw;
}
.hometop-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;

  height: 100vh;
  width: 100vw;
}

.hometop-icon1 {
  position: absolute;
  left: 20vw;
  top: 30vh;
}
.hometop-icon2 {
  position: absolute;
  right: 15vw;
  bottom: 50vh;
}
.hometop-icon3 {
  position: absolute;
  left: 15vw;
  top: 10vh;
}
.hometop-icon4 {
  position: absolute;
  bottom: 80px;
  left: 0;
}

.hometop-title > h1 {
  text-align: center;
  width: 80vw;
  height: 30vh;
  /* background-color: red; */
  position: absolute;
  top: 10vh;
  /* left: 10vw; */
}
.hometop-title > h1 > p {
  text-align: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;

  align-items: center;
  text-align: center;

  color: #808080;
}

.hometop-span {
  font-family: "Plus Jakarta Sans";
  display: block;
  font-weight: 700;
  font-size: 80px;
  line-height: 100%;

  align-items: center;
  text-align: center;

  color: #111727;
}

.hometop-span2 {
  font-family: "Plus Jakarta Sans";
  display: block;
  font-weight: 700;
  font-size: 80px;
  line-height: 100%;

  align-items: center;
  text-align: center;

  color: #111727;
  margin: 0 15px;

  background: linear-gradient(
    to bottom,
    transparent 65%,
    #b2f417 65%,
    #b2f417 85%,
    transparent 50%
  );
}
.special-color-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hometop-btn {
  cursor: pointer;
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  gap: 4px;

  width: 249px;
  height: 56px;

  /* Base/100 */
  border: 1.5px solid #1f1f1f;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  background-color: #ffffff;

  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  position: absolute;
  bottom: calc(20vh + 80px);
}
.hometop-btn-img {
  margin-left: 5px;
}
