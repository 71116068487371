.Download-container {
  height: 100vh;
  width: 100vw;
  background-color: #1f1f1f;
  position: relative;
}
.Download-wrap {
  display: flex;

  position: relative;

  width: 100vw;
  height: 100vh;
}
.Download-left {
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;

  /* background-color: red; */
}
.Download-left-top {
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background-color: red; */
}
.Download-btn-wrap {
  display: flex;
  /* background-color: red; */
  width: 30vw;
  justify-content: space-between;
  margin-top: 5vh;
}
.Download-left-bot {
  display: flex;
}
.Download-btn-img {
  object-fit: contain;
}
.Download-btn-apple {
  object-fit: contain;
  cursor: pointer;
}

.Download-right {
  /* position: absolute;
  top: 0vh;
  right: 0; */
  width: 50vw;
  /* height: 100vh; */
}

.Download-p {
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */
  display: flex;
  align-items: center;

  color: #808080;
}
.Download-g {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 135%;
  /* or 24px */

  /* Base/80 */
  color: #808080;
}
.Download-span {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 105%;
  /* or 50px */

  /* Base/100 */
  color: #ffffff;
}
.Download-span2 {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 105%;
  margin-right: 15px;

  /* or 50px */

  /* Base/100 */
  color: #1f1f1f;
  background: linear-gradient(
    to bottom,
    transparent 65%,
    #b2f417 65%,
    #b2f417 85%,
    transparent 50%
  );
}

.Download-btn-text-wrapper {
  padding-top: 16px;
}


.Download-btn-text {
  /* Add your desired styles for the text link here */
  color: #B2F415;
  text-decoration: none;
  font-weight: bold;
  margin-top: 16px;
}


@media (max-width: 780px) {
  .Download-wrap {
    display: flex;
    flex-direction: column;
  }

  .Download-left {
    width: 100vw;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Download-btn-img {
    width: 80vw;
    height: 60vh;
  }

  .Download-container {
    height: 120vh;
  }

  .Download-left-top {
    height: 40vh;
    width: 100vw;
    /* background-color: blue; */
  }

  .Download-right {
    /* position: absolute;
    top: 0vh;
    right: 0; */
    width: 90vw;
    /* height: 50vh; */
  }
  .Download-span {
    text-align: center;
  }
  .Download-g {
    text-align: center;
  }

  .Download-btn-wrap {
    display: flex;
    /* background-color: red; */
    width: 100vw;
    justify-content: space-evenly;
    margin-top: 5vh;
  }
}
