.navbar {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  bottom: 0;
}
.z-logo {
  width: 50px;
}
.logo-p {
  font-style: italic;
  font-weight: 800;
  font-size: 30px;
  line-height: 120%;
  margin-left: 10px;
}
.navbar-container {
  display: flex;

  align-items: center;
  height: 80px;
  width: 100vw;
  background-color: #1f1f1f;
}

.navbar-logo {
  /* color: rgb(119, 109, 109); */
  width: 20vw;
  color: #ffffff;
  justify-self: start;
  margin-left: 5vw;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;

  /* background-color: red; */
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 50vw;
  justify-content: center;
  margin-right: 2rem;
  /* background-color: red; */
}
.navbar-rightbtn {
  background-color: greenyellow;
  height: 60%;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar-rightbtn-font {
  background-color: red;
  /* justify-content: center; */
  /* align-items: center; */
  /* align-self: center; */
  /* align-content: center; */
  /* justify-self: center; */
  /* justify-items: center; */
}

.nav-item {
  height: 80px;

  /* font-weight: 400; */
}
.nav-item-p1 {
  font-size: 16px;
  line-height: 150%;

  font-weight: 600;
}
.nav-item-p2 {
  font-size: 16px;
  line-height: 150%;

  font-weight: 400;
}

.nav-links {
  /* color: rgb(119, 109, 109); */
  color: #ffffff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0rem 1rem;
  height: 100%;
  font-weight: bold;
  /* font-family: "Open Sans",sans-serif; */
  font-family: "Lato", "Arial", sans-serif;
}

.nav-links:hover {
  border-bottom: 4px solid #b2f417;
  transition: all 0.2s ease-out;
  /* background-color: red; */
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}
.img-logo {
  height: 30px;
}

@media screen and (max-width: 960px) {
  .navbar-rightbtn {
    background-color: greenyellow;
    height: 60%;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    margin-right: 5vw;
  }
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -200vw;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background-color: #000;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    /* position: absolute; */
    top: 0;
    left: 0;
    margin-top: 2%;
    width: 50vw;

    /* transform: translate(0%, 0%); */
  }
  .img-logo {
    height: 30px;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;

    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #000;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}

@media (max-width: 1600px) {
  .navbar-logo {
    /* color: rgb(119, 109, 109); */
    font-size: 24px;
  }
}

@media (max-width: 780px) {
  .navbar-container {
    background-color: #ffffff;
  }

  .logo-p {
    color: #000;
    margin-left: 5px;
  }
  .menu-icon {
    color: #000;
  }

  .fa-bars {
    color: #000;
  }
}

@media (max-width: 450px) {
  .navbar-logo {
    /* color: rgb(119, 109, 109); */
    font-size: 24px;
  }
  .menu-icon {
  }
}
