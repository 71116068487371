.Mobilehomemid-container {
  height: 200vh;
  background-color: #f5f5f5;
  width: 100vw;
  /* min-width: 1432px; */

  padding-top: 0;
  position: relative;
  /* background-color: red; */
  /* max-width: 1200px; */
}
.Mobilehomemid-p {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;

  align-items: center;

  color: #808080;
}
.Mobilehomemid-span-wrap {
  /* background-color: red; */
  width: 90vw;
}

.Mobilehomemid-span2 {
  font-family: "Plus Jakarta Sans";
  font-weight: 700;
  font-size: 40px;
  line-height: 105%;

  color: #1f1f1f;

  background: linear-gradient(
    to bottom,
    transparent 65%,
    #b2f417 65%,
    #b2f417 85%,
    transparent 50%
  );
}
.Mobilehomemid-span {
  font-family: "Plus Jakarta Sans";
  font-weight: 700;
  font-size: 40px;
  line-height: 105%;
  /* or 50px */
  display: flex;
  /* align-items: center; */

  /* Base/100 */
  color: #1f1f1f;
}

.Mobilehomemid-special-color-flex {
  position: relative;
  /* width: 90vw;
  display: flex;
  justify-content: center;
  align-items: center; */
}

.Mobilehomemid-boxcontainer {
  width: 80vw;

  height: 140vh;
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
}
.Mobilehomemid-box1 {
  width: 80vw;
  height: 40vh;
  background-color: #b2f417;
  position: relative;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
.Mobilehomemid-pa {
  position: absolute;
  left: 3vw;
  right: 3vw;
  top: 10vh;
  bottom: 3vh;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #1f1f1f;
}

.Mobilehomemid-box2 {
  margin-top: 10vh;
  width: 80vw;
  height: 40vh;
  background-color: #1f1f1f;
  position: relative;
  display: flex;
  justify-content: center;
}

.Mobilehomemid-pa2 {
  position: absolute;
  left: 3vw;
  right: 3vw;
  top: 10vh;
  bottom: 3vh;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #ffffff;
}
.Mobilehomemid-box3 {
  margin-top: 10vh;
  width: 80vw;
  height: 40vh;
  background-color: #b2f417;
  position: relative;
  display: flex;
  justify-content: center;
}

.Mobilehomemid-icon1 {
  position: absolute;
  /* left: 3vw; */
  top: 3vh;
  width: 56px;
  height: 56px;
}

@media (max-width: 780px) {
  .Mobilehomemid-special-color-flex {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
    /* background-color: red; */
  }

  .Mobilehomemid-wrap {
    /* position: absolute; */
    /* top: 0vh; */
    /* left: 0vw; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
  }
}
