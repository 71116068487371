.homechoose-container {
  height: 100vh;
  width: 100vw;
  background-color: #ffffff;
  position: relative;
}
.homechoose-wrap {
  display: flex;
  position: relative;

  width: 100vw;
  height: 100vh;
}
.homechoose-left {
  width: 60vw;
  /* background-color: red; */
}
.homechoose-left-top {
  position: absolute;
  top: 10vh;
  left: 5vw;
  width: 50vw;
  /* background-color: red; */
}

.homechoose-btn-img {
  /* object-fit: contain; */
  /* width: 45vw;
  height: 100vh; */
  /* height: 100%; */
  /* width: 50%; */
}

.homechoose-right {
  position: absolute;
  top: 0vh;
  right: 0;
  width: 45vw;
  height: 100vh;
  background-image: url("../../images/homechoose/Right.png");

  object-fit: contain;
  background-position: center;
}

.homechoose-p {
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */
  display: flex;
  align-items: center;

  color: #808080;
}
.homechoose-g {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 135%;
  /* or 24px */

  /* Base/80 */
  color: #808080;
}
.homechoose-span {
  font-family: "Plus Jakarta Sans";
  font-weight: 700;
  font-size: 48px;
  line-height: 105%;
  /* or 50px */

  /* Base/100 */
  color: #1f1f1f;
}
.homechoose-span2 {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 105%;
  margin-right: 15px;

  /* or 50px */

  /* Base/100 */
  color: #1f1f1f;
  background: linear-gradient(
    to bottom,
    transparent 65%,
    #b2f417 65%,
    #b2f417 85%,
    transparent 50%
  );
}

.homechoose-left-bot {
  display: flex;

  width: 45vw;
  height: 13vh;
  position: absolute;
  bottom: 20vh;
  background-color: #b2f417;
}

.homechoose-left-bot-font-p1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 100%;

  text-align: center;
}
.homechoose-left-bot-font-p2 {
  font-family: "Roboto";
  font-style: normal;

  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */
  text-align: center;

  /* Base/80 */
  color: #808080;
}

.homechoose-left-bot-font1 {
  margin-left: 10vw;
  /* background-color: red; */
  width: 16vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.homechoose-left-bot-font2 {
  width: 16vw;
  margin-left: 3vw;
  /* background-color: blue; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 780px) {
  .homechoose-container {
    height: 100vh;
    width: 100vw;
    background-color: #ffffff;
    position: relative;
  }
  .homechoose-wrap {
    display: flex;
    flex-direction: column;
    width: 90vw;
  }

  .homechoose-left {
    width: 90vw;
  }

  .homechoose-span {
    font-family: "Plus Jakarta Sans";
    font-weight: 700;
    font-size: 40px;
    line-height: 105%;
    /* or 50px */

    /* Base/100 */
    color: #1f1f1f;
  }
  .homechoose-span2 {
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 105%;
    margin-right: 15px;

    /* or 50px */

    /* Base/100 */
    color: #1f1f1f;
    background: linear-gradient(
      to bottom,
      transparent 65%,
      #b2f417 65%,
      #b2f417 85%,
      transparent 50%
    );
  }

  .homechoose-right {
    display: none;
  }

  .homechoose-left-top {
    position: absolute;
    top: 10vh;
    left: 5vw;
    width: 90vw;
    /* background-color: red; */
  }

  .homechoose-left-bot {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    width: 90vw;
    height: 40vh;
    position: absolute;
    bottom: 5vh;
    left: 5vw;

    background-color: #b2f417;
  }

  .homechoose-left-bot-font1 {
    margin-left: 0;
    /* background-color: red; */
    width: 80vw;
    /* height: 30vh; */
    /* width: 16vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
  }
  .homechoose-left-bot-font2 {
    margin-left: 0;
    /* background-color: red; */
    width: 80vw;
  }
}
