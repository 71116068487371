.homemid-container {
  height: 100vh;
  background-color: #f5f5f5;
  width: 100vw;
  /* min-width: 1432px; */

  padding-top: 0;
  position: relative;
  /* background-color: red; */
  /* max-width: 1200px; */
}
.homemid-p {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;

  align-items: center;

  color: #808080;
}

.homemid-span2 {
  font-family: "Plus Jakarta Sans";
  font-weight: 700;
  font-size: 48px;
  line-height: 105%;
  /* or 50px */
  display: flex;
  align-items: center;

  /* Base/100 */
  color: #1f1f1f;
  margin-right: 15px;

  background: linear-gradient(
    to bottom,
    transparent 65%,
    #b2f417 65%,
    #b2f417 85%,
    transparent 50%
  );
}
.homemid-span {
  font-family: "Plus Jakarta Sans";
  font-weight: 700;
  font-size: 48px;
  line-height: 105%;
  /* or 50px */
  display: flex;
  /* align-items: center; */

  /* Base/100 */
  color: #1f1f1f;
}
.homemid-wrap {
  position: absolute;
  top: 10vh;
  left: 10vw;
}
.homemid-special-color-flex {
  position: relative;
}
.homemid-special-color-flex > h1 {
  display: flex;
  /* flex-direction: row; */
}

.homemid-boxcontainer {
  width: 80vw;

  height: 40vh;
  margin-top: 10vh;
  display: flex;

  /* background-color: red; */
}
.homemid-box1 {
  width: calc(80vw / 3);
  height: 40vh;
  background-color: #b2f417;
  position: relative;
}
.homemid-pa {
  position: absolute;
  left: 3vw;
  right: 3vw;
  top: 10vh;
  bottom: 3vh;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #1f1f1f;
}

.homemid-pa2 {
  position: absolute;
  left: 3vw;
  right: 3vw;
  top: 10vh;
  bottom: 3vh;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #ffffff;
}
.homemid-box2 {
  width: calc(80vw / 3);
  height: 40vh;
  background-color: #1f1f1f;
  position: relative;
}
.homemid-box3 {
  width: calc(80vw / 3);
  height: 40vh;
  background-color: #b2f417;
  position: relative;
}

.homemid-icon1 {
  position: absolute;
  left: 3vw;
  top: 3vh;
  width: 56px;
  height: 56px;
}

@media (max-width: 780px) {
  .homemid-special-color-flex {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
    /* background-color: red; */
  }

  .homemid-wrap {
    /* position: absolute; */
    /* top: 0vh; */
    /* left: 0vw; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
  }

  .homemid-boxcontainer {
    width: 80vw;

    height: 140vh;
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
  }
  .homemid-box1 {
    width: 80vw;
    height: 40vh;
    background-color: #b2f417;
    position: relative;
    display: flex;
    justify-content: center;
    /* align-items: center; */
  }
  .homemid-pa {
    position: absolute;
    left: 3vw;
    right: 3vw;
    top: 10vh;
    bottom: 3vh;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #1f1f1f;
  }

  .homemid-box2 {
    margin-top: 10vh;
    width: 80vw;
    height: 40vh;
    background-color: #1f1f1f;
    position: relative;
    display: flex;
    justify-content: center;
  }

  .homemid-pa2 {
    position: absolute;
    left: 3vw;
    right: 3vw;
    top: 10vh;
    bottom: 3vh;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #ffffff;
  }
  .homemid-box3 {
    margin-top: 10vh;
    width: 80vw;
    height: 40vh;
    background-color: #b2f417;
    position: relative;
    display: flex;
    justify-content: center;
  }
}
