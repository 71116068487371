.body {
  overflow: hidden;
}
.footer-container {
  background-color: #1f1f1f;
  padding: 0rem 0 0rem 0;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  pointer-events: none;
}
.footer-img {
  width: 100vw;
  object-fit: contain;
  /* height: 55vh; */
  /* height: 100%; */
}
.link-text {
  /* color: blue; */
}
.footer-left-side {
  width: 30vw;
  /* height: 25vh; */
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */

  /* height: 30vh; */
  /* margin: 16px; */
  margin-top: 36px;
}
.footer-icon-title {
  object-fit: contain;
}
.foot-p {
  padding-top: 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 135%;
  /* or 24px */

  /* Base/40 */
  color: #808080;
}
.foot-i {
  padding-top: 15px;
}
.footer-icon1 {
  height: 30px;
  width: 30px;
  object-fit: contain;
  margin-right: 15px;
}
.footer-icon {
  height: 30px;
  width: 30px;
  object-fit: contain;
  margin: 0px 15px;
}
.footer-subscription {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 24px;
  padding: 24px;
  color: #fff;
}
.footer-subscription > p {
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

.footer-input {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
}

.footer-links {
  /* width: 100%;
  max-width: 100px; */
  display: flex;
  justify-content: space-around;
  /* align-items: center; */
  height: 30vh;
  width: 90vw;
  /* background-color: blue; */
  /* margin: 15px 15px; */
  /* padding: 15px 15px; */
}

.footer-link-wrapper {
  display: flex;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  width: 160px;
  box-sizing: border-box;
}

.footer-link-items h2 {
  margin-bottom: 16px;
}
.footer-link-items > h2 {
  color: #fff;
}

.footer-link-items a {
  color: #d4d4d4;
  text-decoration: none;
  margin-bottom: 8px;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-in-out;
}

.footer-email-form h2 {
  margin-bottom: 32px;
}

.footer-input:placeholder {
  color: #b1b1b1;
}
.wr-fonts {
  font-weight: bold;
}

/*social icons */

.social-icon-link {
  color: #fff;
  font-size: 24px;
}
.social-media {
  max-width: 1200px;
  width: 100%;
  margin-bottom: 15px;
}

.social-media-wrap {
  border-top: 1px solid #808080;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 2000px;
  margin: 40px auto 0 auto;
}
.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
}
.inquire {
  align-items: center;
  width: 200px;
}
.inquire-box {
  padding: 8px 20px;
  border-radius: 2px;
  margin-right: 10px;
  outline: none;
  border: none;
  font-size: 18px;
  margin-bottom: 16px;
  border: 1px solid #fff;
  color: #fff;
  font-weight: bold;
}
.inquire-font {
  color: #fff;
}

.social-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 1rem;
  /* display: flex; */
  align-self: center;
  margin-bottom: 16px;
  font-weight: bold;
}
.wechat-code {
  margin-left: 40px;
  margin-bottom: 10px;
  height: 80px;
  width: 80px;
}

.website-rights {
  font-weight: 400;
  font-size: 16px;
  line-height: 135%;
  /* or 24px */

  /* Base/60 */
  color: #8c8c8c;
  margin-bottom: 16px;
}

@media (max-width: 780px) {
  .footer-links {
    /* width: 100%;
    max-width: 100px; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    /* justify-content: space-around; */
    /* align-items: center; */
    height: 100vh;
    width: 90vw;
    /* background-color: blue; */
    /* margin: 15px 15px; */
    /* padding: 15px 15px; */
    margin-top: 10vh;
  }

  .footer-icon-title {
    height: 40px;
  }

  .footer-left-side {
    width: 100vw;

    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */

    /* height: 30vh; */
    /* margin: 16px; */
    margin-top: 0px;
  }
}
