.contact-container {
  height: 100vh;
  width: 100vw;
  /* padding-top: 80px; */
  background-color: #ffffff;
}
.green {
  color: #b2f417;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 105%;
  /* or 50px */
  text-align: center;
}
.white {
  color: #ffffff;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 105%;
  /* or 50px */
  text-align: center;
}
.circle-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1f1f1f;

  border-top-left-radius: 1000px;
  border-top-right-radius: 1000px;
}

.form-container {
  /* background-color: red; */
  width: 70vw;
  height: 60vh;
}

.form-wrap {
  display: flex;
  /* justify-content: space-evenly; */
  /* width: 60vw;
  justify-content: space-between; */
  /* align-items: center; */
}
.form-group {
  /* background-color: blue; */
  height: 30px;
  width: 40vw;
  border: none;
  /* text-decoration: underline; */
  margin-top: 5%;
}

.form-input {
  width: 30vw;

  border: none;
  outline: none;
  background-color: #1f1f1f;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 155%;
  /* text-decoration: underline; */
  border-bottom: 1px solid #8c8c8c;
  color: #ffffff;
}

.form-inputbox {
  margin-top: 3vh;
  width: 65vw;
  height: 30vh;
  /* border: none; */
  outline: none;
  background-color: #1f1f1f;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 155%;
  /* text-decoration: underline; */
  border-bottom: 2px solid #8c8c8c;
  color: #ffffff;
}

.submit-btn {
  border: none;
  background-color: #1f1f1f;

  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */
  text-align: center;

  /* Base/0 */
  color: #ffffff;
  /* width: 100px; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  /* width: 80vw; */
}

.img {
  object-fit: contain;
  width: 30px;
  height: 30px;
  margin-right: 10px;

  /* background-color: red; */
}

@media (max-width: 780px) {
  .green {
    color: #b2f417;
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 105%;
    /* or 50px */
    text-align: center;
  }
  .white {
    color: #ffffff;
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 105%;
    /* or 50px */
    text-align: center;
  }
  .title-container {
    /* background-color: red; */
    width: 75vw;
  }
  .form-container {
    width: 90vw;
  }

  .form-wrap {
    display: flex;
    flex-direction: column;
  }

  .form-group {
    height: 30px;
    width: 90vw;
    border: none;

    margin-top: 15%;
  }

  .form-input {
    width: 80vw;
  }

  .contact-container {
    height: 120vh;
    /* background-color: #1f1f1f; */
    padding-bottom: 20vh;
  }

  .circle-container {
    height: 120vh;
    padding-bottom: 20vh;
  }

  .form-inputbox {
    margin-top: 3vh;
    width: 90vw;
    height: 30vh;
  }
}
