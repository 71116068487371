.homevision-container {
  height: 120vh;
  width: 100vw;
  background-color: #f5f5f5;
  position: relative;
  padding-top: 20vh;
}
.homevision-wrap {
  /* display: flex; */
  position: relative;

  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.homevision-left {
  /* width: 60vw; */
  /* background-color: red; */
}
.homevision-left-top {
  /* position: absolute;
  top: 10vh;
  left: 5vw;
  width: 50vw; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 20vh; */

  /* background-color: red; */
}

.homevision-p {
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */
  display: flex;
  align-items: center;

  color: #808080;
  text-align: center;
}
.homevision-left-top > h1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: red; */

  /* background-color: rebeccapurple; */
}
.homevision-g {
  text-align: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 135%;

  width: 50vw;
  /* or 24px */

  /* Base/80 */
  color: #808080;
}
.homevision-span {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 105%;
  /* or 50px */

  /* Base/100 */
  color: #1f1f1f;
}
.homevision-span2 {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 105%;
  margin-right: 15px;

  /* or 50px */

  /* Base/100 */
  color: #1f1f1f;
  background: linear-gradient(
    to bottom,
    transparent 65%,
    #b2f417 65%,
    #b2f417 85%,
    transparent 50%
  );
}

.homevision-left-bot-number {
  display: flex;
  justify-content: space-between;
  width: 50vw;

  /* background-color: red; */
}
.homevision-num {
  object-fit: contain;
}
.homevision-left-bot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
}

.homevision-left-bot-number-active {
  background-color: #b2f417;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 60px;
  font-weight: bold;
  cursor: pointer;
}
.homevision-left-bot-number-inactive {
  background-color: #1f1f1f;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 60px;
  color: #b2f417;
  font-weight: bold;
  cursor: pointer;
}
.homevision-left-bot-pic {
  background-color: #b2f417;

  width: 60vw;
  height: 77vh;
  margin-top: 2vh;

  z-index: 3;
}
.homevision-btn-img-container {
  margin-top: 5vh;
  height: 50vh;
  width: 60vw;

  position: relative;
}
.homevision-btn-img-wrap {
  height: 50vh;
  width: 54vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* margin: 15px 15px; */
  background-color: #ffffff;
  /* margin-left: 15px; */
  margin-left: 2vw;
}
.homevision-btn-img-wrap2 {
  height: 50vh;
  width: 54vw;
  background-color: #1f1f1f;
  position: absolute;
  left: 4vw;
  top: 3vh;
  z-index: -1;
}
.homevision-btn-img {
  object-fit: contain;
  height: 30vh;
  width: 70vw;
  /* width: 70vw; */
}
.homevision-bot-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.homevision-bot-t {
  margin-top: 2vh;
  font-weight: 700;
  font-size: 32px;
  line-height: 105%;
  /* identical to box height, or 34px */

  /* Base/100 */
  color: #1f1f1f;
}
.homevision-bot-p {
  width: 90%;
  border-top: 1px solid #b8d04a;
  margin-top: 2vh;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */
  text-align: center;

  /* Base/80 */
  color: #808080;
}

@media (max-width: 780px) {
  .homevision-g {
    width: 80vw;
  }
  .homevision-span {
    font-size: 40px;
  }
  .homevision-span2 {
    font-size: 40px;
  }
  .homevision-left-top > h1 {
    text-align: center;
  }

  .homevision-left-bot-number {
    display: flex;
    justify-content: space-between;
    width: 90vw;
  }

  .homevision-num {
    object-fit: contain;
    width: 25vw;
  }

  .homevision-left-bot-number-active {
    height: 50px;
    width: 50px;
  }
  .homevision-left-bot-number-inactive {
    height: 50px;
    width: 50px;
  }

  .homevision-left-bot-pic {
    background-color: #b2f417;

    width: 90vw;
    height: 75vh;
    margin-top: 2vh;

    z-index: 3;
  }

  .homevision-container {
    height: 140vh;
    padding-top: 30vh;
  }

  .homevision-btn-img-container {
    width: 80vw;
  }
  .homevision-btn-img-wrap {
    height: 40vh;
    width: 80vw;
    margin-left: 5vw;
  }
  .homevision-btn-img-wrap2 {
    display: none;
  }
}
